import React from "react";
import styles from "../styles/style.js";
import Headings from "./Headings.js";

const OurLocation = () => {
  return (
    <div
      id="where-we-are"
      className={`mt-6 bg-[#ffffff] ${styles.paddingX} ${styles.flexCenter}`}
    >
      <div className={`${styles.boxWidth}`}>
        <Headings
          titleTop="Our Registered"
          titleBottom="Office Address"
          description="Intact Electrical believes that it’s important to our clients, partners and stakeholders that we are transparent in sharing who we are and what we do, but also where we are."
        />

        <section className="text-gray-600 body-font relative mb-12 mt-4">
          <div className="absolute inset-0 bg-gray-300">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2487.242050279168!2d-2.758505173038673!3d51.43534851596383!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4871f3b18af8a3f9%3A0x5b8b498eb6716ff9!2sHeath%20Rd%2C%20Nailsea%2C%20Bristol%20BS48%201AD!5e0!3m2!1sen!2suk!4v1731519206559!5m2!1sen!2suk"
              width="100%"
              height="100%"
              title="map"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
            {/* <iframe
              width="100%"
              height="100%"
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.4301606075705!2d-0.6668396229965717!3d51.52366960951417!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487663582a0c39cb%3A0xf3348ec6ca3a6548!2s46%20Nursery%20Rd%2C%20Taplow%2C%20Maidenhead%20SL6%200JZ!5e0!3m2!1sen!2suk!4v1685638027820!5m2!1sen!2suk"
              style={{ filter: "grayscale(1) contrast(1) opacity(0.8)" }}
              //style={{ filter: "grayscale(1) contrast(1.0) opacity(0.4)" }}
            ></iframe> */}
          </div>
          <div
            className="container px-5 py-24 mx-auto flex"
            style={{ height: "500px" }}
          ></div>
        </section>
      </div>
    </div>
  );
};

export default OurLocation;
